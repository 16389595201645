<template>
    <div>
        <v-btn
            @click="mapVisible = true"
            color="primary"
            :text="!!position"
            small
            block
        >
            <v-icon class="mr-2" small>
                mdi-map-marker
            </v-icon>
            {{ getBtnText }}
        </v-btn>

        <v-subheader
            v-if="geocodeAddress"
            style="display: flex; justify-content: center;"
        >
            {{ geocodeAddress }}
        </v-subheader>

        <MapViewModal
            v-model="value"
            :visible="mapVisible"
            @close="mapVisible = false"
            @confirm="confirmPosition"
        />
    </div>
</template>

<script>
import MapViewModal from '@/components/MapViewModal';

export default {
    name: 'AddressSelector',

    components: {
        MapViewModal,
    },

    props: ['value'],

    data: () => ({
        mapVisible: false,
        position: null,
        geocodeAddress: '',
    }),

    computed: {
        getBtnText() {
            return this.position
                ? 'Trocar endereço'
                : 'Selecionar endereço no mapa';
        },
    },

    methods: {
        confirmPosition(position) {
            this.mapVisible = false;
            this.position = position;

            if (position.results?.length) {
                this.geocodeAddress = position.results[1]?.formatted_address || '';
            }

            this.$emit('confirm', position);
        },

        // used in Envio.vue
        openDialog() {
            this.mapVisible = true;
        },
    },
}
</script>
