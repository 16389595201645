export default {
    between: (coordenadasOrigem, coordenadasDestino) => {
        var latitudeOrigem = coordenadasOrigem.latitude,
            longitudeOrigem = coordenadasOrigem.longitude,
            latitudeDestino = coordenadasDestino.latitude,
            longitudeDestino = coordenadasDestino.longitude;

        // Converts numeric degrees to radians
        const toRad = value => value * Math.PI / 180;

        var R = 6371; // km
        var dLat = toRad(latitudeDestino-latitudeOrigem);
        var dLon = toRad(longitudeDestino-longitudeOrigem);
        latitudeOrigem = toRad(latitudeOrigem);
        latitudeDestino = toRad(latitudeDestino);

        var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(latitudeOrigem) * Math.cos(latitudeDestino);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c;
        return parseFloat(d.toFixed(2));
    }
}