
<template>
    <BaseContainer>
        <template slot="menu">
            <v-app-bar app dark>
                <v-btn icon @click="goBack">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer />
                <span style="text-transform: uppercase; font-weight: bold;">Preencha os campos</span>
                <v-spacer />
            </v-app-bar>
        </template>
        <template slot="principal">
            <v-main>
                <v-card elevation="0" class="mb-12" style="margin-bottom: 150px !important;">
                    <v-card-text>
                        <v-row align="center" justify="center">
                            <v-col cols="6">
                                <v-text-field
                                    v-model="nome"
                                    label="Nome completo"
                                    ref="nome"
                                    prepend-inner-icon="mdi-account"
                                    hide-details
                                    outlined
                                    dense
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="telefone"
                                    label="WhatsApp"
                                    ref="telefone"
                                    prepend-inner-icon="mdi-whatsapp"
                                    v-mask="['(##) # #### ####']"
                                    hide-details
                                    outlined
                                    dense
                                    :disabled="!!cliente.id"
                                />
                            </v-col>
                        </v-row>

                        <!-- <v-row align="center" justify="center">
                            <v-col cols="12">
                                <v-text-field
                                    hide-details
                                    label="E-mail (Opcional)"
                                    v-model="email"
                                />
                            </v-col>
                        </v-row> -->

                        <v-row align="center" justify="center" class="mt-2">
                            <v-col cols="12">
                                <v-btn-toggle
                                    v-model="formaEntrega"
                                    @change="onChangeFormaEntrega"
                                    :style="cssVars"
                                    rounded
                                >
                                    <v-btn
                                        v-if="deliveryEnabled"
                                        value="Delivery"
                                        style="font-size: 10px; height: 60px; width: 30%;"
                                    >
                                        <v-col cols="12">
                                            <v-row
                                                width="100%"
                                                style="place-content: center; margin-bottom: 5px;"
                                            >
                                                <v-icon
                                                    class="mr-2"
                                                    :color="isDelivery ? '#fff' : $vuetify.theme.isDark ? '#fff' : '#000'"
                                                >
                                                    mdi-bike-fast
                                                </v-icon>
                                            </v-row>
                                            <v-row width="100%" style="place-content: center">
                                                <span><b>Entregar</b></span>
                                            </v-row>
                                        </v-col>
                                    </v-btn>

                                    <v-btn
                                        v-if="retiradaEnabled"
                                        value="Retirar no Balcão"
                                        style="font-size: 10px; height: 60px; width: 30%;"
                                    >
                                        <v-col cols="12">
                                            <v-row
                                                width="100%"
                                                style="place-content: center; margin-bottom: 5px;"
                                            >
                                                <v-icon
                                                    class="mr-2"
                                                    :color="isRetirada ? '#fff' : $vuetify.theme.isDark ? '#fff' : '#000'"
                                                >
                                                    mdi-walk
                                                </v-icon>
                                            </v-row>
                                            <v-row width="100%" style="place-content: center">
                                                <span><b>Vou retirar</b></span>
                                            </v-row>
                                        </v-col>
                                    </v-btn>

                                    <v-btn
                                        v-if="localEnabled"
                                        value="Mesa"
                                        style="font-size: 10px; height: 60px; width: 30%;"
                                    >
                                        <v-col cols="12">
                                            <v-row
                                                width="100%"
                                                style="place-content: center; margin-bottom: 5px;"
                                            >
                                                <v-icon
                                                    class="mr-2"
                                                    :color="isLocal ? '#fff' : $vuetify.theme.isDark ? '#fff' : '#000'"
                                                >
                                                    mdi-map-marker
                                                </v-icon>
                                            </v-row>
                                            <v-row width="100%" style="place-content: center">
                                                <span><b>Consumir<br/>no local</b></span>
                                            </v-row>
                                        </v-col>
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>
                        </v-row>

                        <v-row
                            v-if="formaEntrega && permitirAgendamento && !agendamentoObrigatorio"
                            style="margin-top: -10px; margin-bottom: -10px;"
                        >
                            <v-col class="mt-2">
                                <div>Quando será a entrega / retirada?</div>
                                <v-radio-group
                                    v-model="agendado"
                                    row
                                    hide-details
                                    style="margin-top: 5px;"
                                >
                                    <v-radio
                                        label="Hoje"
                                        :value="false"
                                    />
                                    <v-radio
                                        label="Agendar"
                                        :value="true"
                                    />
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row v-if="formaEntrega && agendado">
                            <v-col class="py-1">
                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="agendamentoFormatted"
                                            label="Data do agendamento"
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details
                                            outlined
                                            dense
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="agendado_data"
                                        no-title
                                        @input="onInputDate"
                                        locale="pt-br"
                                        :allowed-dates="allowedDates"
                                        dense
                                        hide-details
                                    />
                                </v-menu>
                            </v-col>
                            <v-col v-if="agendamentoExibirHorario" class="py-1">
                                <v-select
                                    v-model="agendado_hora"
                                    label="Horário"
                                    :items="horarios"
                                    :disabled="!agendado_data"
                                    prepend-inner-icon="mdi-clock-outline"
                                    hide-details
                                    dense
                                    outlined
                                />
                            </v-col>
                        </v-row>

                        <v-row v-if="showAddressSelector" align="center" justify="center">
                            <v-col cols="12">
                                <AddressSelector
                                    ref="addressSelector"
                                    @confirm="confirmPosition"
                                    v-model="mapCurrentGeolocation"
                                />
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="isDelivery">
                            <v-col cols="12">
                                <v-autocomplete
                                    v-if="showMunicipioAutocomplete"
                                    :items="municipios"
                                    return-object
                                    clearable
                                    outlined
                                    dense
                                    label="Selecione o seu Município"
                                    v-model="municipio"
                                    @change="onChangeMunicipio"
                                    item-value="id"
                                    item-text="descricao"
                                />
                                <v-autocomplete
                                    v-if="showBairroAutocomplete"
                                    :items="getBairros"
                                    v-model="bairro"
                                    @change="onChangeBairro"
                                    return-object
                                    clearable
                                    hide-details
                                    outlined
                                    dense
                                    label="Selecione o seu Bairro"
                                    item-value="id"
                                    item-text="descricao"
                                />
                                <v-text-field
                                    v-else
                                    v-model="bairro"
                                    label="Bairro"
                                    hide-details
                                    outlined
                                    dense
                                    @change="onAddressChange"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="isDelivery">
                            <v-col cols="8">
                                <v-text-field
                                    label="Rua / Logradouro / Avenida"
                                    v-model="rua"
                                    hide-details
                                    outlined
                                    dense
                                    @change="onAddressChange"
                                />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    hide-details
                                    label="Número da residência"
                                    v-model="numero"
                                    outlined
                                    dense
                                    @keydown="validateAlphaNumericSpaces"
                                    @paste="onPaste"
                                    @change="onAddressChange"
                                />
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="isDelivery">
                            <v-col cols="6">
                                <v-text-field
                                    hide-details
                                    label="Ponto de referência"
                                    v-model="complemento"
                                    outlined
                                    dense
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    hide-details
                                    label="Observação"
                                    v-model="observacaoGeral"
                                    outlined
                                    dense
                                />
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="isDelivery">
                            <v-col cols="12">
                                <v-radio-group
                                    v-model="tipoLocal"
                                    row
                                    hide-details
                                    style="margin-top: 5px;"
                                >
                                    <v-radio
                                        label="Casa"
                                        value="casa"
                                    />
                                    <v-radio
                                        label="Apartamento"
                                        value="apartamento"
                                    />
                                    <v-radio
                                        label="Trabalho"
                                        value="trabalho"
                                    />
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" v-if="isDelivery && tipoLocal === 'apartamento'">
                            <v-col cols="6">
                                <v-text-field
                                    hide-details
                                    label="Nome do prédio"
                                    v-model="nomeEdificio"
                                    outlined
                                    dense
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    hide-details
                                    label="Nº do apartamento"
                                    v-model="numeroApartamento"
                                    outlined
                                    dense
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="showCupom">
                            <v-col cols="12">
                                <v-subheader @click="cupom.show = !cupom.show" style="margin: -15px;">
                                    Você tem um cupom? <v-icon class="mr-2">mdi-chevron-down</v-icon>
                                </v-subheader>
                                <v-text-field
                                    v-if="cupom.show"
                                    v-model="cupom.codigo"
                                    @change="onChangeCupom"
                                    placeholder="Informe aqui seu cupom"
                                    prepend-inner-icon="mdi-ticket-percent-outline"
                                    hide-details
                                    clearable
                                    outlined
                                    dense
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <FormaPagamentoSelect
                                    @change="formaPagamento = $event"
                                    :items="formasPagamento"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    hide-details
                                    label="Troco para quanto?"
                                    prefix="R$"
                                    v-model="troco"
                                    placeholder="0.00"
                                    v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                    v-if="formaPagamento.descricao == 'Dinheiro' && precisaTroco"
                                    outlined
                                    dense
                                />
                                <v-checkbox
                                    v-if="formaPagamento.descricao == 'Dinheiro'"
                                    dense
                                    :false-value="true"
                                    :true-value="false"
                                    v-model="precisaTroco"
                                    @change="onChangePrecisaTroco"
                                    label="Não preciso de troco"
                                />
                                <v-btn
                                    small
                                    class="mt-4"
                                    @click="copyPixKey"
                                    v-if="showPixDetails"
                                >
                                    Copiar Chave Pix
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="showPixDetails" style="margin-top: -15px;">
                            <v-col cols="12" style="display: flex;">
                                <div class="ellipsis mr-8">
                                    <v-list-item-avatar size="22">
                                        <v-icon small color="#1e1e1e" class="grey lighten-1" dark>mdi-key</v-icon>
                                    </v-list-item-avatar>
                                    {{ configuracoes.tipo_pix }}
                                </div>
                                <div class="ellipsis">
                                    <v-list-item-avatar size="22">
                                        <v-icon small color="#1e1e1e" class="grey lighten-1" dark>mdi-account</v-icon>
                                    </v-list-item-avatar>
                                    {{ configuracoes.nome_pix }}
                                </div>
                            </v-col>
                        </v-row>
                        <input type="hidden" id="pixKey" :value="configuracoes.chave_pix" readonly>
                    </v-card-text>
                </v-card>

                <v-footer fixed class="pa-4">
                    <table style="width: 220px;">
                        <tr>
                            <td style="width: 110px; font-size: 14px;">Subtotal</td>
                            <td style="font-size: 14px; font-weight: bold; text-align: right;">
                                R$ {{ formatPrice(+getSubtotal) }}
                            </td>
                        </tr>
                        <tr v-if="valorAcrescimo">
                            <td style="font-size: 14px;">Acréscimo</td>
                            <td style="font-size: 14px; font-weight: bold; text-align: right;">
                                R$ {{ formatPrice(+valorAcrescimo) }}
                            </td>
                        </tr>
                        <tr v-if="valorDesconto">
                            <td style="font-size: 14px;">Desconto</td>
                            <td style="font-size: 14px; font-weight: bold; text-align: right;">
                                R$ {{ formatPrice(+valorDesconto) }}
                            </td>
                        </tr>
                        <tr v-if="valorPremio">
                            <td style="font-size: 14px; color: #4caf50;">
                                <v-icon small color="green">mdi-gift-outline</v-icon>
                                Prêmio
                            </td>
                            <td style="font-size: 14px; color: #4caf50; font-weight: bold; text-align: right;">
                                - R$ {{ formatPrice(valorPremio) }}
                            </td>
                        </tr>
                        <tr v-if="valorFrete">
                            <td style="font-size: 14px;">Taxa de Entrega</td>
                            <td
                                :style="(freteGratis ? 'text-decoration: line-through;' : '')
                                    + 'font-weight: bold; font-size: 14px; text-align: right;'"
                            >
                                R$ {{ formatPrice(+valorFrete) }}
                            </td>
                        </tr>
                        <tr>
                            <td style="font-size: 20px; font-weight: bold;">Total</td>
                            <td style="font-size: 20px; font-weight: bold; text-align: right;">
                                R$ {{ formatPrice(+getTotal + +getValorFrete) }}
                            </td>
                        </tr>
                    </table>

                    <!-- <MercadoPagoCheckout ref="mercadoPago" /> -->

                    <v-spacer/>
                     <v-btn
                        v-if="onlinePayment"
                        @click="onPayClick"
                        :loading="loading"
                        color="success"
                        x-large
                    >
                        <v-icon>mdi-cash</v-icon>&nbsp;
                        Pagar
                    </v-btn>
                    <v-btn
                        v-else
                        @click="send()"
                        :loading="loading"
                        color="success"
                        x-large
                    >
                        <v-icon>mdi-whatsapp</v-icon>&nbsp;
                        Enviar
                    </v-btn>
                </v-footer>

                <v-dialog v-model="dialogFinalizado" persistent max-width="300">
                    <v-card>
                        <v-card-title class="headline justify-center">
                            Pedido Confirmado!
                        </v-card-title>
                        <v-card-text class=text-center>
                            <v-icon color="green" style="font-size: 80px;">
                                mdi-checkbox-marked-circle-outline
                            </v-icon>
                            <br>
                            <br>
                            <b>Seu pedido já está na<br>fila de preparo.</b>
                            <br>
                            Envie o pedido no WhatsApp e acompanhe o andamento!

                            <!-- <v-progress-linear :value="timer" class="mt-6" color="green"/> -->
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn
                                color="green"
                                class="white--text"
                                @click="sendWhatsapp"
                                block
                            >
                                <v-icon>mdi-whatsapp</v-icon>&nbsp;
                                Enviar no WhatsApp
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <OutOfStockDialog
                    v-model="outOfStockDialog.data"
                    :visible="outOfStockDialog.visible"
                    @close="outOfStockDialogClose"
                    @onGoBack="goBack"
                    @onContinue="send()"
                />
            </v-main>
        </template>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
// import MercadoPagoCheckout from './MercadoPagoCheckout';
import AddressSelector from './AddressSelector';
import OutOfStockDialog from './OutOfStockDialog';
import FormaPagamentoSelect from './FormaPagamentoSelect';
import distance from '@/utils/distance';
import gmapsInit from '@/plugins/gmaps';
import { clone, isMobile } from '@/utils/utils';
import { isOpen, requireScheduling } from '@/utils/openingHours';
import { mapState } from 'vuex';
import {
    total,
    fracao,
    subtotal,
    subtotalGeral,
    valorTotalComplementos,
} from '@/utils/calc';

export default {
    name: 'Envio',

    components: {
        BaseContainer,
        AddressSelector,
        OutOfStockDialog,
        // MercadoPagoCheckout,
        FormaPagamentoSelect,
    },

    data: () => ({
        // email: '',
        msg: '',
        nome: '',
        rua: '',
        troco: '',
        bairro: null,
        municipio: null,
        agendado: false,
        numero: '',
        pixKey: '',
        telefone: '',
        complemento: '',
        formaEntrega: '',
        agendado_hora: '',
        agendado_data: '',
        formaPagamento: '',
        observacaoGeral: '',
        valorFrete: 0,
        tipoLocal: null,
        nomeEdificio: null,
        numeroApartamento: null,

        formaEntregaDisabled: {
            delivery: false,
            retirada: false
        },

        deliveryEnabled: false,
        retiradaEnabled: false,
        localEnabled: false,

        menu: null,

        timer: 0,
        comanda: null,
        cart: [],
        municipios: [],
        bairros: [],
        distancias: [],
        formasPagamento: [],
        foraRaioEntrega: false,
        coordenadas: null,
        loading: false,
        dialogFinalizado: false,
        outOfStockDialog: {
            visible: false,
            data: {},
        },
        googleMaps: null,
        mapCurrentGeolocation: null,
        cupom: { codigo: '', descontos: null, show: false },
        precisaTroco: true,
    }),

    computed: {
        ...mapState([
            'configuracoes',
            'orderBump',
            'cliente',
        ]),

        cssVars() {
            return {
                '--bg-color': this.$theme.primary,
                '--color': '#fff',
                'width': '100%',
            };
        },

        getTipoLocal() {
            const parts = [];

            parts.push(this.tipoLocal);

            if (this.tipoLocal === 'apartamento') {
                parts.push(this.nomeEdificio);
                parts.push(this.numeroApartamento);
            }

            return parts.join(', ');
        },

        getBairros() {
            if (!this.municipio?.id) {
                return this.bairros;
            }

            return this.bairros.filter(b => (!b.municipio_id || (b.municipio_id == this.municipio.id)));
        },

        isFretePorBairro() {
            return this.configuracoes?.frete_tipo === 'Bairro';
        },

        isFretePorMunicipio() {
            return this.configuracoes?.frete_tipo === 'Município';
        },

        isFretePorDistancia() {
            return this.configuracoes?.frete_tipo === 'Distância';
        },

        isFretePorQuilometragem() {
            return this.configuracoes?.frete_tipo === 'Quilometragem';
        },

        isFretePorDistanciaQuilometragem() {
            return ['Distância', 'Quilometragem'].includes(this.configuracoes?.frete_tipo);
        },

        isFreteACombinar() {
            return this.configuracoes?.frete_tipo === 'A combinar';
        },

        isDelivery() {
            return this.formaEntrega === 'Delivery';
        },

        isRetirada() {
            return this.formaEntrega == 'Retirar no Balcão';
        },

        isLocal() {
            return this.formaEntrega === 'Mesa';
        },

        showMunicipioAutocomplete() {
            return this.municipios.length;
        },

        showBairroAutocomplete() {
            if (this.isFreteACombinar) {
                return false;
            }

            const count = this.bairros.filter(b => b.municipio_id == this.municipio?.id).length;

            return (count > 0) || this.isFretePorBairro;
        },

        showAddressSelector() {
            const { latitude, longitude } = this.configuracoes?.geolocalizacao || {};
            return this.isDelivery && !this.isFreteACombinar && latitude && longitude;
        },

        phone() {
            return '55' + (this.configuracoes?.whatsapp || '').replace(/\D/g, '');
        },

        getBairro() {
            return this.bairro?.descricao || this.bairro || '';
        },

        getMunicipio() {
            return this.municipio?.descricao || this.municipio || '';
        },

        freteGratis() {
            return this.cupom.descontos?.frete_gratis || this.pedidoMinimoFreteGratis;
        },

        pedidoMinimoFreteGratis() {
            const pedidoMinimo = this.configuracoes.pedido_minimo_frete_gratis;
            const valorLimiteTaxaGratis = this.configuracoes.valor_limite_taxa_gratis;

            if (valorLimiteTaxaGratis && this.valorFrete > valorLimiteTaxaGratis) {
                return false;
            }

            return ![null, undefined].includes(pedidoMinimo) && this.getSubtotalGeral >= +pedidoMinimo;
        },

        getValorFrete() {
            return this.freteGratis ? 0 : this.valorFrete;
        },

        getSubtotalGeral() {
            return subtotalGeral(this.cart);
        },

        getSubtotal() {
            return this.cart.reduce((a, b) => a + +subtotal(b), 0);
        },

        getTotal() {
            return total(this.cart, this.valorDesconto, this.valorAcrescimo);
        },

        valorDesconto() {
            const cupomPercentual = +(this.cupom.descontos?.percentual || 0);
            const cupomValor = +(this.cupom.descontos?.valor) || 0;

            const formaPgtoPercentual = +(this.formaPagamento.desconto_percentual || 0);
            const formaPgtoValor = +(this.formaPagamento.desconto_valor) || 0;

            let desconto = 0;

            cupomValor && (desconto += cupomValor);
            cupomPercentual && (desconto += this.getSubtotalGeral * cupomPercentual / 100);

            formaPgtoValor && (desconto += formaPgtoValor);
            formaPgtoPercentual && (desconto += this.getSubtotalGeral * formaPgtoPercentual / 100);

            return desconto || 0;
        },

        valorAcrescimo() {
            const totPed = this.getSubtotalGeral + this.getValorFrete;
            const percentual = +(this.formaPagamento.acrescimo_percentual || 0);
            const valor = +(this.formaPagamento.acrescimo_valor) || 0;

            let acrescimo = 0;

            valor && (acrescimo += valor);
            percentual && (acrescimo += totPed * percentual / 100);

            return Math.round(acrescimo * 100) / 100;
        },

        valorPremio() {
            const premios = this.cart.filter(a => a.premio);

            if (!premios?.length) {
                return 0;
            }

            return premios.reduce((a, b) => a + +subtotal(b), 0);
        },

        permitirAgendamento() {
            return this.configuracoes.agendamento?.ativo;
        },

        agendamentoObrigatorio() {
            return requireScheduling();
        },

        agendamentoExibirHorario() {
            return !this.configuracoes.agendamento?.ocultarHorario;
        },

        agendamentoFormatted() {
            return this.agendado_data
                ? this.moment(this.agendado_data).format('DD/MM/YYYY')
                : '';
        },

        impressao() {
            return this.configuracoes.impressao;
        },

        horarios() {
            return this.gerarHorarios();
        },

        showCupom() {
            return this.configuracoes.cupons;
        },

        onlinePayment() {
            return this.formaPagamento?.pagamento_online;
        },

        showPixDetails() {
            return this.formaPagamento.descricao == 'Pix' && this.configuracoes.chave_pix && !this.onlinePayment;
        },
    },

    mounted() {
        this.cart = this.$store.getters.getCart;

        if (!this.cart.length) {
            this.goBack();
        }

        this.setConfiguracao();
        this.googleMapsInit();
        this.dadosCache();
        this.setCupom();
        this.addOrderBumpInCart();

        if (this.configuracoes.facebook_pixel_id) {
            window.fbq('track', 'InitiateCheckout');
        }
    },

    methods: {
        setCupom() {
            const codigo = this.$store.getters.getCupom;
            if (!codigo) {
                return;
            }
            this.cupom = { codigo, descontos: null, show: true };
            this.onChangeCupom();
        },

        allowedDates(val) {
            const dayOfWeek = this.moment(val).isoWeekday();
            const isHojeAdiante = this.moment(val).isSameOrAfter(this.moment().startOf('day'));
            const horarios = this.configuracoes?.agendamento.horarios;

            const currentDay = horarios.find(e => e.isoWeekday == dayOfWeek);

            return isHojeAdiante && currentDay.aberto;
        },

        obterHorarios(intervalo) {
            const horarios = [];
            const formato = 'HH:mm';

            const [horarioInicio, horarioFim] = intervalo.split(' - ');
            const horaInicio = this.moment(horarioInicio, formato);
            const horaFim = this.moment(horarioFim, formato);
            const horaAtual = this.moment();

            const intervaloHorarioMinutos = this.configuracoes?.agendamento.intervaloHorarioMinutos || 30;
            const tempoEntrega = this.configuracoes.tempo_entrega.split(' - ')[0];
            const tempoRetirada = this.configuracoes.tempo_retirada.split(' - ')[0];
            const tempo = this.isDelivery ? tempoEntrega : tempoRetirada;

            const agendamentoCarencia = this.moment().add(tempo, 'minutes');
            const agendamentoMesmoDia = this.moment(this.agendado_data).isSame(horaAtual, 'day');

            while (horaInicio.isBefore(horaFim)) {
                const start = horaInicio.format(formato);
                horaInicio.add(intervaloHorarioMinutos, 'minutes');
                const end = horaInicio.format(formato);

                const horaStart = this.moment(start, 'HH:mm');
                if (agendamentoMesmoDia && horaStart.isBefore(agendamentoCarencia)) {
                    continue;
                }
                horarios.push(`${start} - ${end}`);
            }

            return horarios;
        },

        gerarHorarios() {
            const horarios = [];
            const { agendado_data, configuracoes, moment } = this;

            if (!agendado_data) {
                return horarios;
            }

            const dayOfWeek = moment(agendado_data).isoWeekday();
            const agendamentoHorarios = configuracoes?.agendamento.horarios;

            const {
                horario,
                segundoHorario,
                segundoHorarioAtivo
            } = agendamentoHorarios.find(e => e.isoWeekday === dayOfWeek);

            horarios.push(...this.obterHorarios(horario));

            if (segundoHorarioAtivo) {
                horarios.push(...this.obterHorarios(segundoHorario));
            }

            return horarios;
        },

        goBack() {
            this.$router.push(`/${this.$route.params.emp}`);
        },

        async setConfiguracao() {
            this.bairros = this.configuracoes.bairros;
            this.distancias = this.configuracoes.distancias;
            this.municipios = this.configuracoes.municipios;

            const formasEntrega = this.configuracoes?.formas_entrega;
            this.retiradaEnabled = formasEntrega?.find(e => e.descricao === 'Retirar no Balcão');
            this.deliveryEnabled = formasEntrega?.find(e => e.descricao === 'Delivery');
            this.localEnabled = formasEntrega?.find(e => e.descricao === 'Mesa');

            const enabledOptions = [
                this.retiradaEnabled, this.deliveryEnabled, this.localEnabled
            ].filter(e => e);

            if (enabledOptions.length === 1) {
                this.formaEntrega =
                    this.retiradaEnabled?.descricao
                    || this.deliveryEnabled?.descricao
                    || this.localEnabled?.descricao;
            }

            this.formasPagamento = this.configuracoes.formas_pagamento;

            this.agendamentoObrigatorio && (this.agendado = true);

            this.mapCurrentGeolocation = this.configuracoes?.geolocalizacao
                ? {
                    latitude: +this.configuracoes.geolocalizacao.latitude,
                    longitude: +this.configuracoes.geolocalizacao.longitude,
                }
                : null;
        },

        dadosCache() {
            let dados = localStorage.getItem('zapermenu-userdata');
            if (dados) {
                dados = JSON.parse(dados);

                dados.rua && (this.rua = dados.rua);
                dados.nome && (this.nome = this.cliente.nome || dados.nome);
                dados.numero && (this.numero = dados.numero);
                dados.telefone && (this.telefone = this.cliente.whatsapp || dados.telefone);
                dados.complemento && (this.complemento = dados.complemento);
                dados.observacao && (this.observacaoGeral = dados.observacao);
                dados.tipoLocal && (this.tipoLocal = dados.tipoLocal);
                dados.nomeEdificio && (this.nomeEdificio = dados.nomeEdificio);
                dados.numeroApartamento && (this.numeroApartamento = dados.numeroApartamento);

                this.bairro = this.showBairroAutocomplete
                    ? this.bairros.find(b => b.descricao == dados.bairro)
                    : dados.bairro;

                this.municipio = this.showMunicipioAutocomplete
                    ? this.municipios.find(b => b.descricao == dados.municipio)
                    : dados.municipio;

            } else {
                this.telefone = this.cliente.whatsapp;
                this.nome = this.cliente.nome;
            }

            this.onChangeFormaEntrega();
        },

        validarCampos() {
            const { status } = isOpen();
            const agendamentoAtivo = this.configuracoes.agendamento?.ativo;

            if (agendamentoAtivo && !status && !this.agendado) {
                this.agendado = true;
                this.notify('Desculpe! Ja encerramos o atendimento, você pode agendar seu pedido', 'warning');
                return false;
            }

            if (!status && !agendamentoAtivo) {
                this.notify('Desculpe! Ja encerramos o atendimento', 'warning');
                return false;
            }

            if (this.isDelivery && this.foraRaioEntrega) {
                this.notify('Desculpe! Fora do raio de entrega', 'warning');
                return false;
            }

            const minRetirada = this.configuracoes.pedido_minimo_retirada;
            if (this.isRetirada && minRetirada && minRetirada > +this.getTotal) {
                this.notify(`Valor minimo do pedido é de R$ ${(+minRetirada).toFixed(2)}`, 'warning');
                return false;
            }

            const minEntrega = this.configuracoes.pedido_minimo_entrega;
            if (this.isDelivery && minEntrega && minEntrega > +this.getTotal) {
                this.notify(`Valor minimo do pedido é de R$ ${(+minEntrega).toFixed(2)}`, 'warning');
                return false;
            }

            if (!this.nome) {
                this.notify('Informe seu nome', 'warning');
                return false;
            }
            if (this.telefone.length < 15) {
                this.notify('Informe o Whatsapp com DDD', 'warning');
                return false;
            }
            if (!this.formaEntrega) {
                this.notify('Selecione a forma de entrega', 'warning');
                return false;
            }

            if (this.agendado || this.agendamentoObrigatorio) {
                if (!this.agendado_data) {
                    this.notify('Informe a data do agendamento', 'warning');
                    return false;
                }
                if (!this.agendado_hora) {
                    this.notify('Informe a hora do agendamento', 'warning');
                    return false;
                }
            }

            if (this.agendado && this.agendado_data && this.agendamentoExibirHorario && typeof this.agendado_hora != 'string') {
                this.notify('Informe a hora do agendamento', 'warning');
                return false;
            }

            if (!this.formaPagamento.id) {
                this.notify('Informe a forma de pagamento', 'warning');
                return false;
            }

            if (this.troco && this.formaPagamento.descricao == 'Dinheiro') {
                const total = +this.getTotal + +this.getValorFrete;
                if (this.troco < total) {
                    this.notify('Confira o campo troco', 'warning');
                    return false;
                }
            }

            if (this.isDelivery) {
                if (this.isFretePorDistanciaQuilometragem && !this.coordenadas) {
                    this.notify('Selecione o endereço', 'warning');
                    return false;
                }
                if (this.showMunicipioAutocomplete && !this.municipio) {
                    this.notify('Informe o município', 'warning');
                    return false;
                }
                if (!this.bairro) {
                    this.notify('Informe o bairro', 'warning');
                    return false;
                }
                if (!this.rua) {
                    this.notify('Informe a rua', 'warning');
                    return false;
                }
                if (!this.numero) {
                    this.notify('Informe o numero', 'warning');
                    return false;
                }
                if (!this.tipoLocal) {
                    this.notify('Informe o tipo da localidade', 'warning');
                    return false;
                }

                if (this.tipoLocal === 'apartamento' && !this.nomeEdificio) {
                    this.notify('Informe o nome do edificio', 'warning');
                    return false;
                }

                if (this.tipoLocal === 'apartamento' && !this.numeroApartamento) {
                    this.notify('Informe o número do apartamento', 'warning');
                    return false;
                }
            }

            this.saveUserData();

            return true;
        },

        getWhatsappMessage(pedido) {
            let text = '';
            let total = this.getTotal || 0;

            if (this.agendado) {
                const data = this.moment(this.agendado_data).format('DD/MM/YYYY');
                const hora = this.agendado_hora;
                text += `*AGENDADO PARA*: ${data} ${hora}\n\n`;
            }
            text += `*Nome*: ${this.nome}\n`;
            this.telefone && (text += `*WhatsApp*: ${this.telefone}\n`);
            text += `*Forma Entrega*: ${this.formaEntrega}\n`;
            if (pedido.prazo_finalizacao) {
                text += `*Prazo Estimado*: ${this.moment(pedido.prazo_finalizacao).format('DD/MM/YYYY HH:mm')}\n`;
            }
            text += `*Pagamento*: ${this.formaPagamento.descricao}\n`;

            if (this.isDelivery) {
                const parts = [];

                this.isFretePorMunicipio && parts.push(this.getMunicipio);
                parts.push(this.getBairro);
                parts.push(this.rua);
                parts.push(this.numero);
                parts.push(this.getTipoLocal);
                parts.push(this.complemento);

                text += `*Endereço*: ${parts.join(', ')}\n`;
            }
            this.observacaoGeral && (text += `*Obs*: ${this.observacaoGeral}\n`);
            this.cupom.codigo && (text += `*Cupom*: ${this.cupom.codigo}\n`);
            text += `______________________________________\n\n`;

            const complementos = item => {
                item?.passos?.forEach(passo => {
                    let adicional = this.getValorTotalComplementos(passo);
                    text += `   >>> ${passo.descricao} ${adicional ? `_${adicional}_` : ''} <<<\n`;

                    passo.complementos.forEach(adicional => {
                        text += `    *${fracao(passo, adicional)}* ${adicional.descricao}\n`;
                    });
                });
            };

            this.cart.forEach(i => {
                const qtd = i.quantidade || 1;
                const valor = +i.valor ? `(R$${i.valor})` : '';
                const premio = i.premio ? '(PRÊMIO)' : '';

                text += `*${qtd}* ${i.descricao} ${valor} ${premio}\n`;

                i.produtos?.forEach(item => {
                    text += `  *${item.descricao}*\n`;
                    complementos(item);
                });

                complementos(i);

                i.observacao && (text += `_Obs: (${i.observacao})_\n`);

                text += '______________________________________\n';
            });

            if (this.isDelivery) {
                text += `*TOTAL PRODUTOS*: R$${this.formatPrice(+this.getSubtotal)}\n`;
                this.valorPremio && (text += `*PRÊMIO*: - R$${this.formatPrice(this.valorPremio)}\n`);

                const taxaEntrega = this.isFreteACombinar
                    ? 'A Combinar'
                    : `R$${this.formatPrice(this.getValorFrete)}`;

                text += `*TAXA DE ENTREGA*: ${taxaEntrega}\n`;
                text += `*TOTAL FINAL*: R$${this.formatPrice(this.getValorFrete + +total)}\n`;

                this.troco && this.formaPagamento.descricao === 'Dinheiro' && (text += `\n*Troco para*: R$${this.troco}`);
                this.formaPagamento.descricao === 'Pix' && (text += '\nApós o pagamento pix, envie o comprovante.');
            } else {
                this.valorPremio && (text += `*PRÊMIO*: - R$${this.formatPrice(this.valorPremio)}\n`);
                text += `*TOTAL*: R$${total}`;

                this.troco && this.formaPagamento.descricao == 'Dinheiro' && (text += `\n*Troco para*: R$${this.troco}`);
                text += '\n\nAssim que seu pedido estiver pronto a gente avisa você 😉';
            }

            text += `\n\nMuito obrigado pela preferência!\n`;

            if (this.isDelivery) {
                text += this.coordenadas
                    ? `https://maps.google.com/?q=${this.coordenadas.latitude},${this.coordenadas.longitude}`
                    : '_Para facilitar a entrega envie-nos a Localização Fixa do Whatsapp_';
            }

            return new Promise(resolve => resolve(text));
        },

        async send(requirePayment = false) {
            // if (requirePayment) {
            //     this.$refs.mercadoPago.getCardToken();
            //     return;
            // }

            if (!this.validarCampos()) {
                return;
            }

            this.loading = true;

            try {
                const { data } = await this.criarComanda();

                const { type, outOfStock, data: pedido } = data;

                if (type === 'warning' && outOfStock?.length) {
                    this.outOfStock(outOfStock);
                    return;
                }

                if (requirePayment) {
                    this.$router.push(`/${this.$route.params.emp}/pagamento/${pedido.uuid}`);
                    return;
                }

                await this.mountWhatsappMessage(pedido);
                this.finishOrder();
            } catch (error) {
                // this.$http.post('logger', {error, pedido: this.comanda, msg: this.msg});
                console.log(error);
            } finally {
                this.loading = false;
            }
        },

        finishOrder() {
            this.sendPurchaseEvent();
            this.clear();
        },

        async mountWhatsappMessage(pedido) {
            this.msg = await this.getWhatsappMessage(pedido);

            const baseURL = (document.URL.indexOf('localhost') !== -1)
                ? 'http://localhost:8082'
                : 'https://app.zapermenu.com.br';

            let header = `*${this.configuracoes.fantasia}*`;
            header += `\n\n*PEDIDO #${pedido.id}*`;

            this.msg = `${header}\n${this.msg}`;

            this.impressao?.linkImpressaoWhatsapp !== false &&
                (this.msg += `\n🖨️ ${baseURL}/imprimir/${this.$route.params.emp}/${pedido.id}`);

            this.msg = window.encodeURIComponent(this.msg);

            this.dialogFinalizado = true;
        },

        outOfStock(produtos) {
            this.setCart([]);

            this.outOfStockDialog = {
                visible: true,
                data: {
                    outOfStock: produtos,
                },
            };
        },

        sendPurchaseEvent() {
            if (!this.configuracoes.facebook_pixel_id) {
                return;
            }

            const total = +this.getTotal || 0;

            window.fbq('track', 'Purchase', {
                value: total,
                currency: 'BRL',
                content_type: 'product',
            });
        },

        sendWhatsapp() {
            const baseUrl = isMobile() ? 'whatsapp://' : 'https://api.whatsapp.com/';

            window.open(`${baseUrl}send?phone=${this.phone}&text=${this.msg}`);

            this.$router.push(`/${this.$route.params.emp}`);
        },

        clear() {
            this.$store.commit('setCart', []);
        },

        saveUserData() {
            const data = {
                rua: this.rua,
                nome: this.nome,
                bairro: this.getBairro,
                numero: this.numero,
                telefone: this.telefone,
                tipoLocal: this.tipoLocal,
                nomeEdificio: this.nomeEdificio,
                municipio: this.getMunicipio,
                complemento: this.complemento,
                observacao: this.observacaoGeral,
                numeroApartamento: this.numeroApartamento,
            };
            localStorage.setItem('zapermenu-userdata', JSON.stringify(data));
        },

        onChangePrecisaTroco(v) {
            if (!v) {
                this.troco = null;
            }
        },

        async onChangeCupom() {
            if (!this.cupom.codigo?.trim()) {
                this.cupom = { codigo: '', descontos: null, show: false };
                return;
            }

            await this.$http.get(`cupom/${this.cupom.codigo.trim()}`).then(resp => {
                this.cupom.descontos = resp.data;
            });

            if (this.cupom.descontos) {
                this.notify('Parabéns, Cupom Aplicado!');
                return
            }

            this.notify('Cupom inválido ou expirado', 'warning');
            this.cupom.codigo = '';
        },

        onChangeMunicipio() {
            if (!this.isDelivery) {
                return;
            }

            this.bairro = null;

            if (!this.isFretePorMunicipio || !this.formaEntrega) {
                return;
            }

            this.valorFrete = +this.municipio?.valor || 0;
            this.valorFrete && !this.freteGratis
                && this.notify('Taxa de entrega R$' + this.valorFrete.toFixed(2));
        },

        onChangeBairro() {
            if (!this.isDelivery) {
                return;
            }

            if (this.isFretePorDistanciaQuilometragem) {
                this.onAddressChange();
                return;
            }

            if (!this.formaEntrega) {
                return;
            }

            this.valorFrete = +this.bairro?.valor || 0;
            this.valorFrete && !this.freteGratis
                && this.notify('Taxa de entrega R$' + this.valorFrete.toFixed(2));
        },

        onChangeFormaEntrega() {
            if (!this.isDelivery) {
                this.valorFrete = 0;
                return;
            }

            if (this.isFretePorBairro) {
                this.onChangeBairro();
            }
            if (this.isFretePorMunicipio) {
                this.onChangeMunicipio();
            }
            if (this.isFretePorDistanciaQuilometragem) {
                this.onAddressChange();
                // this.$nextTick(() => this.$refs.addressSelector.openDialog());
            }
            // Acombinar
        },

        validarRaioEntrega(pos, dis = null) {
            this.foraRaioEntrega = false;

            let km = dis;

            if (pos) {
                this.coordenadas = {
                    latitude: pos.lat,
                    longitude: pos.lng
                };

                km = distance.between(this.configuracoes.geolocalizacao, this.coordenadas);
            }

            const limiteEntrega = Math.max.apply(Math, this.distancias.map(o => o.fim));

            if (km > limiteEntrega) {
                this.foraRaioEntrega = true;
                this.notify('Desculpe! Fora do raio de entrega', 'warning');
                return;
            }

            this.distancias.forEach(f => {
                if (km >= +f.inicio && km <= +f.fim) {
                    this.valorFrete = +f.valor;
                    this.notify('Taxa de entrega R$' + this.valorFrete.toFixed(2));
                }
            });
        },

        onBtnOkClick() {
            this.$router.push(`/${this.$route.params.emp}`);
        },

        addOrderBumpInCart() {
            this.cart = this.cart.concat(this.orderBump);
        },

        criarComanda() {
            this.cart.forEach(i => {
                i.produtos?.forEach(e => {
                    e.produto_id = clone(e.produto_id || e.id);
                    e.id = null;
                });

                let type = i.hasOwnProperty('produtos') ? 'combo_id' : 'produto_id';
                i[type] = clone(i[type] || i.id);
                i.id = null;
                i.quantidade = i.quantidade || 1;
                i.total = +subtotal(i);
            });

            const ddmmyyyy = this.moment(this.agendado_data).format('YYYY-MM-DD');
            const hhmm = this.agendado_hora;
            const agendado_datahora = this.agendado ? `${ddmmyyyy} ${hhmm}` : null;

            this.comanda = {
                app: true,
                pessoas: 1,
                pronto: false,
                impresso: false,
                cliente: this.nome,
                troco: this.troco || 0,
                whatsapp: this.telefone,
                agendado: this.agendado,
                produtos: clone(this.cart),
                valor_frete: this.getValorFrete,
                cupom: this.cupom.codigo || null,
                entrega: this.isDelivery ? 2 : (this.isLocal ? 3 : 1),
                desconto: this.valorDesconto || 0,
                acrescimo: this.valorAcrescimo || 0,
                frete_gratis: this.freteGratis,
                observacao: this.observacaoGeral || '',
                forma_pagamento: this.formaPagamento.id,
                agendado_datahora,
            };

            const ads = this.$store.getters.getAds;
            const mesa = this.$store.getters.getMesa;

            ads && (this.comanda.observacao += `\nAds: ${ads}`);
            mesa && (this.comanda.mesa = mesa);

            if (this.isDelivery) {
                this.comanda.endereco = {
                    rua: this.rua || '',
                    numero: this.numero || '',
                    bairro: this.getBairro || '',
                    municipio: this.getMunicipio || '',
                    complemento: this.complemento || '',
                    tipo_local: this.tipoLocal || '',
                    nome_edificio: this.nomeEdificio || '',
                    numero_apartamento: this.numeroApartamento || '',
                };
                this.coordenadas && (this.comanda.endereco.coordenadas = {
                    latitude: this.coordenadas?.latitude || '',
                    longitude: this.coordenadas?.longitude || '',
                });
            }

            this.comanda = clone(this.comanda);

            return this.$http.post('pedido', this.comanda);
        },

        getValorTotalComplementos(passo) {
            const total = valorTotalComplementos(passo);
            return total ? `(Adicional R$${total.toFixed(2)})` : '';
        },

        async copyPixKey() {
            const pixKey = document.querySelector('#pixKey');
            pixKey.setAttribute('type', 'text');
            pixKey.select();

            try {
                this.notify('Chave pix copiada!');
                document.execCommand('copy');
            } catch (err) {
                this.notify('Não foi possivel copiar!');
            }

            pixKey.setAttribute('type', 'hidden');
            window.getSelection().removeAllRanges();
        },

        confirmPosition(position) {
            if (position.results?.length) {
                const components = position.results[1]?.address_components || '';
                components.forEach(e => {
                    if (e.types[0] == 'route') {
                        this.rua = e.short_name;
                    }
                    if (e.types[0] == 'street_number') {
                        this.numero = e.short_name;
                    }
                    if (!this.isFretePorBairro && e.types[0] == 'political') {
                        this.bairro = e.short_name;
                    }
                });
            }

            this.coordenadas = {
                latitude: position.lat,
                longitude: position.lng
            };

            if (this.isFretePorDistanciaQuilometragem) {
                this.validarRaioEntrega(position);
                return;
            }

            this.notify('Localização confirmada!');
        },

        validateAlphaNumericSpaces(event) {
            const keyCode = event.keyCode;
            if (
                (keyCode >= 48 && keyCode <= 57) || // números
                (keyCode >= 65 && keyCode <= 90) || // letras maiúsculas
                (keyCode >= 97 && keyCode <= 122) || // letras minúsculas
                keyCode === 32 || // espaço em branco
                keyCode === 8 || // tecla de retrocesso (backspace)
                keyCode === 46 || // tecla de delete
                keyCode === 37 || // tecla de seta para a esquerda
                keyCode === 96 || // tecla 0
                keyCode === 39 // tecla de seta para a direita
            ) {
                // permitir a entrada de caracteres permitidos
                return true;
            } else {
                // bloquear a entrada de caracteres indesejados
                event.preventDefault();
                return false;
            }
        },

        onPaste(event) {
            event.preventDefault();
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedText = clipboardData.getData('text');
            const sanitizedText = pastedText.replace(/[^\w\s]/gi, ''); // remover caracteres indesejados
            document.execCommand('insertText', false, sanitizedText); // colar o texto sanitizado
        },

        onInputDate() {
            this.menu = false;
            this.agendado_hora = [];
        },

        async googleMapsInit() {
            if (!this.isFretePorDistanciaQuilometragem) {
                return;
            }

            try {
                this.googleMaps = await gmapsInit();
            } catch (e) {
                console.log(e)
            }
        },

        onAddressChange() {
            const parts = [];

            if (!this.rua || !this.numero || !this.getBairro) {
                return;
            }

            parts.push(this.getMunicipio || this.configuracoes.municipio);
            parts.push(this.configuracoes.uf);
            this.getBairro && parts.push(this.getBairro);
            this.rua && parts.push(this.rua);
            this.numero && parts.push(this.numero);

            const address = parts.join(', ');

            if (this.isFretePorQuilometragem) {
                this.matrix(address);
            }

            if (this.isFretePorDistancia) {
                this.geocoder(address);
            }
        },

        matrix(address) {
            const { latitude, longitude } = this.configuracoes?.geolocalizacao || {};
            const destination = `${latitude}, ${longitude}`;

            const service = new this.googleMaps.maps.DistanceMatrixService();

            service.getDistanceMatrix(
                {
                    origins: [address],
                    destinations: [destination],
                    travelMode: 'DRIVING',
                },
                (response, status) => {
                    if (status === 'OK') {
                        console.log(response)
                        const results = response.rows[0].elements[0];

                        if (results.status === 'OK') {
                            const distance = results.distance.value; // Distância em metros
                            // const duration = results.duration.text; // Tempo estimado

                            // this.notify(
                            //     `Distância calculada: ${distance / 1000} km, Tempo estimado: ${duration}`
                            // );

                            this.validarRaioEntrega(null, distance / 1000);
                        } else if (results.status === 'NOT_FOUND') {
                            this.notify('Endereço não encontrado');
                        } else if (results.status === 'ZERO_RESULTS') {
                            this.notify('Não há rotas disponíveis para esse endereço');
                        }
                    } else {
                        this.notify('Falha ao calcular a distância');
                        console.log(`Falha ao calcular distância. Status: ${status}`);
                    }
                }
            );
        },

        geocoder(address) {
            const geocoder = new this.googleMaps.maps.Geocoder();

            geocoder.geocode({ address }, (results, status) => {
                if (status === 'OK') {
                    const lat = results[0].geometry.location.lat();
                    const lng = results[0].geometry.location.lng();

                    this.mapCurrentGeolocation = { latitude: lat, longitude: lng };

                    this.validarRaioEntrega({ lat, lng });
                 } else if (status === 'ZERO_RESULTS') {
                    this.notify('Endereço não encontrado');
                } else {
                    this.notify('Falha na geocodificação');
                    console.log(`Falha na geocodificação. Status: ${status}`);
                }
            });
        },

        outOfStockDialogClose() {
            this.outOfStockDialog = {
                visible: false,
                data: {},
            };
        },

        onPayClick() {
            const REQUIRE_PAYMENT = true;
            this.send(REQUIRE_PAYMENT);
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 10px 0 10px;
}

/* .v-card__text {
    padding: 0 24px 0 !important;
} */

.v-btn--active {
    background-color: var(--bg-color) !important;
    color: var(--color) !important;
}

.v-application--is-ltr .v-list-item__avatar:first-child {
    margin-right: 5px;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
