
<template>
    <v-dialog v-model="visible" persistent max-width="300">
        <v-card>
            <v-card-title class="headline justify-center">
                <v-icon color="primary" style="font-size: 80px;">
                    mdi-emoticon-confused-outline
                </v-icon>
                Produto sem estoque!
            </v-card-title>

            <v-card-text v-if="isCartEmpty" class="text-center mt-2">
                Lamentamos, mas este produto não possui mais estoque suficiente, volte ao cardápio para continuar seu pedido.
            </v-card-text>

            <v-card-text v-else class="text-center">
                <ul class="my-2">
                    <li v-for="(produto, i) in value.outOfStock" :key="i" style="font-weight: bold;">
                        {{ produto.descricao }} | Disponível: {{ produto.estoque }}
                    </li>
                </ul>

                <!-- <span class="mt-4">
                    <br>Os produtos acima não possuem estoque suficiente no momento.<br><br>

                    Você pode continuar e <b>finalizar o pedido</b> com os produtos restantes
                    ou <b>voltar ao cardápio</b> e adicionar mais produtos ao seu pedido <br>antes de finalizá-lo.
                </span> -->
                <span class="mt-4">
                    <br>Lamentamos, os produtos acima não possuem mais estoque suficiente no momento.<br><br>

                    Você pode <b>voltar ao cardápio</b> e adicionar outros produtos ao seu pedido antes de finalizá-lo.
                </span>
            </v-card-text>

            <!-- <v-card-actions>
                <v-btn
                    v-if="!isCartEmpty"
                    @click="finalizar"
                    color="green"
                    class="white--text"
                    block
                >
                    <v-icon>mdi-whatsapp</v-icon>
                    <span class="ml-1">Finalizar pedido</span>
                </v-btn>
            </v-card-actions> -->
            <v-card-actions>
                <v-btn
                    @click="voltar"
                    color="primary"
                    class="white--text"
                    block
                >Voltar ao cardápio</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    name: 'OutOfStockDialog',

    props: {
        value: {
            type: Object,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        isCartEmpty() {
            return false;
            // return !this.value?.cart?.length;
        },
    },

    methods: {
        ...mapMutations({
            setInitialized: 'setInitialized',
        }),

        finalizar() {
            this.$emit('onContinue');
            this.$emit('close');
        },

        voltar() {
            this.setInitialized(false);

            this.$emit('onGoBack');
            this.$emit('close');
        },
    },
}
</script>

<style scoped>
</style>
