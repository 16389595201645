<template>
  <div id="map" :style="styleComputed" />
</template>

<script>
import gmapsInit from '@/plugins/gmaps';

export default {
  name: 'MapView',
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
    position: Object,
  },

  async mounted() {
    this.init();
  },

  beforeDestroy() {
    this.google.maps.event.removeListener(this.clickListener);
  },

  computed: {
    styleComputed() {
      if (this.flat) {
        const h = this.$vuetify.breakpoint.smAndDown ? '180px' : '270px';
        return `
          border-radius: 6px !important;
          height: ${h};
          max-width: 100%;
        `;
      }

      const { height } = this.$vuetify.breakpoint;
      const h = height * 0.58;

      return `
        border-radius: 6px !important;
        height: ${h}px;
        max-width: 100%;
      `;
    },
  },

  // watch: {
  //   position(v) {
  //     console.log(v)
  //     // this.handlePosition({
  //     //   lat: v.latitude,
  //     //   lng: v.longitude,
  //     //   zoom: true,
  //     // });
  //   },
  // },

  methods: {
    async init() {
      try {
        this.google = await gmapsInit();
        const center = {
          lat: this.position?.latitude || -23.544889,
          lng: this.position?.longitude || -46.638381,
        };
        this.map = new this.google.maps.Map(this.$el, {
          center,
          gestureHandling: 'greedy',
          zoom: this.position ? 13 : 6,
          disableDefaultUI: this.flat,
          // controls
          zoomControl: !this.flat,
          mapTypeControl: !this.flat,
          scaleControl: !this.flat,
          streetViewControl: !this.flat,
          rotateControl: !this.flat,
          fullscreenControl: !this.flat,
        });

        if (this.position) {
          // this.marker = new this.google.maps.Marker({
          //   position,
          //   map: this.map,
          // });
        }

        this.setListeners();
      } catch (error) {
        // console.log("map-view error", error);
      }
    },

    handlePosition({ lat, lng }) {
      const position = { lat, lng };

    //   if (!this.marker) {
    //     this.marker = new this.google.maps.Marker({
    //       position,
    //       map: this.map,
    //     });
    //   } else {
    //     this.marker.setPosition(position);
    //   }

    //   setTimeout(() => {
    //     this.map.panTo(position);
    //     zoom && this.map.setZoom(14);
    //   }, 200);

      this.map.panTo(position);
      this.map.setZoom(18);

      // Update new position
      const nextPosition = {
        latitude: lat,
        longitude: lng,
      };
      this.$emit('onChangePosition', nextPosition);
    },

    setListeners() {
      if (this.flat) {
        return;
      }

      this.clickListener = this.map.addListener('click', async () => {
        // const lat = e.latLng.lat();
        // const lng = e.latLng.lng();

        const centerPosition = await this.getCenterPosition();

        this.handlePosition(centerPosition);
      });
    },

    async getCenterPosition() {
      const center = this.map.getCenter();
      const lat = center.lat();
      const lng = center.lng();

      const geocoder = new this.google.maps.Geocoder();
      const { results } = await geocoder.geocode({ location: { lat, lng } });

      return { lat, lng, results };
    },
  },
};
</script>

<style scoped>
#map {
  height: 100%;
  margin: 0;
  position: relative;
}

#map:after {
  width: 22px;
  height: 40px;
  display: block;
  content: ' ';
  position: absolute;
  top: 50%; left: 50%;
  margin: -40px 0 0 -11px;
  background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png');
  background-size: 22px 40px; /* Since I used the HiDPI marker version this compensates for the 2x size */
  pointer-events: none; /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}
</style>
